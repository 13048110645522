import Vue from "../../main.js";

const serviceUrl =  "product";
const get = `${serviceUrl}/`;
const getAll = `${serviceUrl}/all`;
const create = `${serviceUrl}/create`;
const update = `${serviceUrl}/update`;

/**
 * Export
 */
export default {
  async create(data) {
    return Vue.http.post(create, data, { });
  },
  async update(data) {
    return Vue.http.patch(update, data, { });
  },
  async getAll() {
    return Vue.http.post(getAll);
  },
  async get(id) {
    return Vue.http.post(
      get,
      { id: id }
    );
  }
};
