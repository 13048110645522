<template>
<v-layout align-center justify-center>
    <v-flex xs12 sm8 md10>
        <material-card class="elevation-12 rounded-card" :title="title" icon="mdi-pasta">
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Name/Nombre:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                                <v-text-field solo class="right-input" v-model.trim="form.name" :error-messages="nameErrors" maxlength="20" required @input="$v.form.name.$touch()" @blur="$v.form.name.$touch()"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Green Min:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                                <v-text-field solo class="right-input" v-model="form.greenRangeMinValue" type="number" :error-messages="greenMinErrors" min="0" required @input="$v.form.greenRangeMinValue.$touch()" @blur="$v.form.greenRangeMinValue.$touch()"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Green Max:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                                <v-text-field solo class="right-input" v-model="form.greenRangeMaxValue" type="number" required :error-messages="greenMaxErrors" min="0" @input="$v.form.greenRangeMaxValue.$touch()" @blur="$v.form.greenRangeMaxValue.$touch()"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Orange Min:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                                <v-text-field solo class="right-input" v-model="form.orangeRangeMinValue" :error-messages="orangeMinErrors" type="number" max="0" required @input="$v.form.orangeRangeMinValue.$touch()" @blur="$v.form.orangeRangeMinValue.$touch()"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Orange Max:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                                <v-text-field solo class="right-input" v-model="form.orangeRangeMaxValue" :error-messages="orangeMaxErrors" type="number" min="0" required @input="$v.form.orangeRangeMaxValue.$touch()" @blur="$v.form.orangeRangeMaxValue.$touch()"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Red Min:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                                <v-text-field solo class="right-input" v-model="form.redRangeMinValue" :error-messages="redMinErrors" type="number" max="0" required @input="$v.form.redRangeMinValue.$touch()" @blur="$v.form.redRangeMinValue.$touch()"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="2">
                                <v-subheader class="label-left">Red Max:</v-subheader>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                                <v-text-field solo class="right-input" v-model="form.redRangeMaxValue" :error-messages="redMaxErrors" type="number" required min="0" @input="$v.form.redRangeMaxValue.$touch()" @blur="$v.form.redRangeMaxValue.$touch()"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="align-center justify-center">
                <v-btn class="action" color="blue" v-on:click.prevent="save" :disabled="$v.$invalid" :loading="isLoading">
                    {{button}}
                    <v-icon right>mdi-content-save</v-icon>
                </v-btn>
                <v-btn @click="goBack" color="red">
                    Back/Regresar
                    <v-icon right>mdi-cancel</v-icon>
                </v-btn>
            </v-card-actions>
        </material-card>
    </v-flex>
</v-layout>
</template>

<script>
import {
    required,
    email,
    sameAs,
    minLength,
    requiredIf
} from "vuelidate/lib/validators";
import {
    mapActions,
    mapGetters
} from "vuex";
import products from "../../services/api/product";
import VJsoneditor from "v-jsoneditor";

const greenMinValid = (value, vm) => {
    return parseInt(value) < parseInt(vm.greenRangeMaxValue);
};

const greenMaxValid = (value, vm) => {
    return parseInt(value) > parseInt(vm.greenRangeMinValue);
};

const orangeMinValid = (value, vm) => {
    return parseInt(value) < parseInt(vm.orangeRangeMaxValue);
};

const orangeMaxValid = (value, vm) => {
    return parseInt(value) > parseInt(vm.orangeRangeMinValue);
};

const redMinValid = (value, vm) => {
    return parseInt(value) < parseInt(vm.redRangeMaxValue);
};

const redMaxValid = (value, vm) => {
    return parseInt(value) > parseInt(vm.redRangeMinValue);
};

export default {
    name: "user",
    components: {
        VJsoneditor,
        MaterialCard: () => import('@/components/base/MaterialCard'),
    },
    data() {
        return {
            form: {
                name: "",
                greenRangeMinValue: 50,
                greenRangeMaxValue: 70,
                orangeRangeMinValue: -3,
                orangeRangeMaxValue: 3,
                redRangeMinValue: -4,
                redRangeMaxValue: 5
            },
            isCreate: true
        };
    },
    validations: {
        form: {
            name: {
                required
            },
            greenRangeMinValue: {
                required,
                greenMinValid
            },
            greenRangeMaxValue: {
                required,
                greenMaxValid
            },
            orangeRangeMinValue: {
                required,
                orangeMinValid
            },
            orangeRangeMaxValue: {
                required,
                orangeMaxValid
            },
            redRangeMinValue: {
                required,
                redMinValid
            },
            redRangeMinValue: {
                required,
                redMinValid
            },
            redRangeMaxValue: {
                required,
                redMaxValid
            }
        }
    },
    async created() {
        this.isCreate = this.type == "new";
        if (!this.isCreate) {
            const response = await products.get(this.$route.params.id);
            this.form = response.data;
            this.form.qaFormat = JSON.parse(this.form.qaFormat);
        }
    },
    methods: {
        ...mapActions(["login"]),
        async save() {
            let data = Object.assign({}, this.form);
            data.qaFormat = JSON.stringify(this.form.qaFormat);
            try {
                if (this.isCreate) {
                    await products.create(data);
                } else {
                    await products.update(data);
                }
                this.$router.push({
                    name: "productsList"
                });
            } catch (e) {
                console.error(e);
                this.$toast.error("There was an error saving/Hubo un error al guardar");
            }
        },
        goBack() {
            this.$router.push({
                name: "productsList"
            });
        }
    },
    props: {
        type: {
            type: String
        }
    },
    computed: {
        ...mapGetters(["isLoading"]),
        title() {
            return this.isCreate ?
                "NEW PRODUCT/NUEVO PRODUCTO" :
                "EDIT PRODUCT/EDITAR PRODUCTO";
        },
        button() {
            return this.isCreate ? "Create/Crear" : "Edit/Editar";
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.form.name.$dirty) return errors;
            !this.$v.form.name.required && errors.push("Required/Requerido");
            return errors;
        },
        greenMinErrors() {
            const errors = [];
            if (!this.$v.form.greenRangeMinValue.$dirty) return errors;
            parseInt(this.form.greenRangeMinValue) >=
                parseInt(this.form.greenRangeMaxValue) &&
                errors.push("Less than/Menor que GreenMax");
            return errors;
        },
        greenMaxErrors() {
            const errors = [];
            if (!this.$v.form.greenRangeMaxValue.$dirty) return errors;
            parseInt(this.form.greenRangeMaxValue) <=
                parseInt(this.form.greenRangeMinValue) &&
                errors.push("Less than/Menor que OrangeMin");
            return errors;
        },
        orangeMinErrors() {
            const errors = [];
            if (!this.$v.form.orangeRangeMinValue.$dirty) return errors;
            parseInt(this.form.orangeRangeMinValue) >=
                parseInt(this.form.orangeRangeMaxValue) &&
                errors.push("Less than/Menor que OrangeMax");
            return errors;
        },
        orangeMaxErrors() {
            const errors = [];
            if (!this.$v.form.orangeRangeMaxValue.$dirty) return errors;
            parseInt(this.form.orangeRangeMaxValue) <=
                parseInt(this.form.orangeRangeMinValue) &&
                errors.push("Less than/Menor que RedMin");
            return errors;
        },
        redMinErrors() {
            const errors = [];
            if (!this.$v.form.redRangeMinValue.$dirty) return errors;
            parseInt(this.form.redRangeMinValue) >=
                parseInt(this.form.redRangeMaxValue) &&
                errors.push("Less than/Menor que RedMax");
            return errors;
        },
        redMaxErrors() {
            const errors = [];
            if (!this.$v.form.redRangeMaxValue.$dirty) return errors;
            parseInt(this.form.redRangeMaxValue) <=
                parseInt(this.form.redRangeMinValue) &&
                errors.push("More than/Mayor que RedMin");
            return errors;
        }
    }
};
</script>
